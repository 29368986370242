.background
{
    clip-path: polygon(0 0, 100% 1%, 100% 58%, 0% 100%);
}
.seller
{
    background-image: url("../resources/seller.jpg");
    background-size: cover;
}
.blurrr
{
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(6px);
    border-radius: 15px;
}
.driver
{
    background-image: url("../resources/driver.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    
}
.age{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.card-container {
    perspective: 1000px;
  }
  
 /* From Uiverse.io by SteveBloX */ 
.card {
  box-sizing: border-box;
  
  height: auto;
  
  backdrop-filter: blur(6px);
  border-radius: 17px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  font-weight: bolder;
  color: black;
}

.card:hover {
  border: 1px solid #8B014A;
  transform: scale(1.05);
}

.card:active {
  transform: scale(0.95) rotateZ(1.7deg);
}
  


/* Adjust the PhoneInput container to ensure proper alignment */
.phone-input-container {
  position: relative;
}

.phone-input-label {
  transition: all 0.3s ease-in-out;
}

/* Hide label when not needed */
.phone-input-label.peer-placeholder-shown {
  top: 50%;
  left: 1rem; /* Adjust as needed */
  font-size: 1rem; /* Adjust as needed */
  color: #6b7280; /* Adjust as needed */
}
