
/* Add this to your CSS file */
.bg-newsletter {
  background-color: #15191B; /* Fallback color */
}
.bg-newsletter {
  background-image: url('../resources/slide1.jpeg'); /* Adjust the path as necessary */
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}




.golden-button {
    touch-action: manipulation;
    display: inline-block;
    outline: none;
    font-family: inherit;
    font-size: 1em;
    box-sizing: border-box;
    border: none;
    border-radius: 0.3em;
    height: 2.75em;
    line-height: 2.5em;
    text-transform: uppercase;
    padding: 0 1em;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(110, 80, 20, 0.4),
      inset 0 -2px 5px 1px rgba(139, 66, 8, 1),
      inset 0 -1px 1px 3px rgba(250, 227, 133, 1);
    background-image: linear-gradient(
      160deg,
      #a54e07,
      #b47e11,
      #fef1a2,
      #bc881b,
      #a54e07
    );
    border: 1px solid #a55d07;
    color: rgb(120, 50, 5);
    text-shadow: 0 2px 2px rgba(250, 227, 133, 1);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background-size: 100% 100%;
    background-position: center;
  }
  
  .golden-button:focus,
  .golden-button:hover {
    background-size: 150% 150%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23),
      inset 0 -2px 5px 1px #b17d10, inset 0 -1px 1px 3px rgba(250, 227, 133, 1);
    border: 1px solid rgba(165, 93, 7, 0.6);
    color: rgba(120, 50, 5, 0.8);
  }
  
  .golden-button:active {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(110, 80, 20, 0.4),
      inset 0 -2px 5px 1px #b17d10, inset 0 -1px 1px 3px rgba(250, 227, 133, 1);
  }
  


  