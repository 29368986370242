body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Monserrat'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* src/index.css */
@import 'animate.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes slide-in {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(5px);
  }
}

.list-item-hover:hover {
  animation: slide-in 0.3s ease forwards;
  color: #88014A;
}



