.contact
{
    clip-path: polygon(0 0, 100% 1%, 100% 85%, 0% 100%);
    background-image: url("../resources/bgsmall.png");
}


/* From Uiverse.io by dylanharriscameron */ 
.cards {
    position: relative;
    width: 100%;
    height: 250px;
    border-radius: 14px;
    z-index: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
    ;
  }
  
  .bg {
    position: absolute;
    width: 98%;
    height: 240px;
    z-index: 2;
    background: rgb(255, 255, 255);
    
    backdrop-filter: blur(24px);
    border-radius: 10px;
    overflow: hidden;
    outline: 1px solid white;
  }
  
  .blob {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 250px;
    border-radius: 100%;
    background-color: #8b014ba6;
    opacity: 1;
    filter: blur(12px);
    animation: blob-bounce 2s infinite ease;
  }
  .hwl{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    margin-bottom: 4px;
    margin-top: 4px;
    /* border: 1px solid red; */
  }
 #rti{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
#rrti{
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
}
#qwi{
  /* border: 1px solid red; */
  padding: 4px;
}
  @keyframes blob-bounce {
    0% {
      transform: translate(-100%, -100%) translate3d(0%, 0%, 0);
    }
  
    25% {
      transform: translate(-100%, -100%) translate3d(100%, 0, 0);
    }
  
    50% {
      transform: translate(-100%, -100%) translate3d(100%, 100%, 0);
    }
  
    75% {
      transform: translate(-100%, -100%) translate3d(0, 100%, 0);
    }
  
    100% {
      transform: translate(-100%, -100%) translate3d(0, 0, 0);
    }
  }
  
 
  