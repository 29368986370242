
 .container{

 }
 .pricing-content{}
.single-pricing{
background:#fff;
padding:40px 10px;
border-radius:10px;
/* position:relative; */
z-index:1;

/* box-shadow: 0 10px 500px -100px rgba(0,64,128,.09); */
transition:0.5s;
transition-timing-function:linear;
/* transition-duration:1s; */
}
@media only screen and (max-width:480px) { 
.single-pricing {margin-bottom:30px;}
}
.single-pricing:hover{
box-shadow:1px 1px 10px rgba(34, 34, 34, 0.651);
z-index:10;
transform: translate(0px,-20px);
border:0px groove #eeeeee00;
border-bottom: 0px;
}
.price-label {
    color: #fff;
	background: #8B014A;
	/* box-shadow:1px 2.5px 4px rgba(250, 55, 55, 0.651); */
	font-size: 22px;
	width: 120px;
	margin-top: -15px;
	display: block;
	z-index: 2;
	-webkit-clip-path: polygon(100% 0%, 90% 50%, 100% 100%, 0% 100%, 0 50%, 0% 0%);
	clip-path: polygon(100% 0%, 90% 50%, 100% 100%, 0% 100%, 0 50%, 0% 0%);
	margin-left: -10px;

	/* position: absolute; */
}
.price-head h2 {
	font-weight: 600;
	margin-bottom: 0px;
	text-transform: capitalize;
	font-size: 26px;
}
.price-head span {
	display: inline-block;
	background:#8B014A;
	width: 9px;
	height: 9px;
	border-radius: 30px;
	margin-bottom: 20px;
	margin-top: 15px;
	gap: 1px;
	box-shadow:1px 2.5px 4px rgba(34, 34, 34, 0.651);
}
#prc{
	border-radius: 10px;
	/* background-color: white; */
	border-bottom:1.9px groove #b1b1b1;
	/* padding-bottom: 5px; */
}
.price {
	font-weight: 500;
	font-size: 50px;
	margin-bottom: 0px;
}
.single-pricing{}
.single-pricing h5 {
	font-size: 14px;
	margin-bottom: 0px;
	text-transform: uppercase;
}
.single-pricing ul{
list-style: none;
margin-bottom: 20px;
margin-top: 30px;
}

.single-pricing ul li{line-height: 35px;}
.single-pricing #start {
background: rgb(190, 184, 184);
border: 2px solid #8B014A;
font-weight: 700;
border-radius: 5000px;
color: #8B014A;
display: inline-block;
font-size: 16px;
overflow: hidden;
padding:10px 45px;
text-transform: capitalize;
transition: all 0.3s ease 0s;
}
.signal-pricing #start{
	background: white;
}
.single-pricing #start:hover, .single-pricing #start:focus{
background:#8B014A;
color:#fff;
border: 2px solid #8B014A;
}
.single-pricing-white{
	background: #3d4953;
	box-shadow:1px 0px 5px 1.9px  #09162eda inset ,-2px 0px 5px 1.4px #c0f0ff inset, 0 1.3px 1.6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
 
/**/

}
.single-pricing-white:hover{
	background: #3f4e5a;
	box-shadow:2px 0px 2.4px 1px  #0000007c inset ,-2px 0px 2.4px 1px  #e9e9e9c7 inset;
	
}
/* .pricing-white{background: #464650f3} */
.single-pricing-silver{ 
	background: rgb(170, 170, 170);
	box-shadow:2px 0px 5px 1px  #3b3b3bd3 inset ,-2px 0px 5px 1px  #ffffff inset;
}
.single-pricing-silver:hover{ 
	background: rgb(212, 212, 212);
	box-shadow:2px 0px 2.4px 1px  #3b3b3ba2 inset ,-2px 0px 2.4px 1px  #ffffff inset;
}
.single-pricing-gold{ 
	background: #a8742f;
	box-shadow:2px 0px 4.5px 1px  #160e05f5 inset ,-2px 0px 4.5px 1px #ffb95d inset;
}
.single-pricing-gold:hover{ 
	background: #be8232;
	box-shadow:2px 0px 2.4px 1px  #160e0580 inset ,-2px 0px 2.4px 1px #ffb759 inset;
}

.single-pricing-white  ul li{color:#fff;}
.single-pricing-white h2{color:#fff;}
.single-pricing-white h1{color:#fff;}
.single-pricing-white h5{color:#fff;}