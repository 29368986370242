/* From Uiverse.io by MuhammadHasann */ 




#bdr{
  /* box-shadow: 0 10px 500px -100px rgba(0,64,128,.09); */
/* transition:0.5s; */
/* transition-timing-function:linear; */
border-color: rgba(224, 224, 224, 0.658);
border-style: groove;
/* transition-duration:1s; */
}
#bdr:hover{
  /* box-shadow:1px 1px 10px rgba(34, 34, 34, 0.651);
  z-index:10;
  transform: translate(25px,0px);
  border-color: rgba(51, 121, 100, 0.699); */
}
#Bdr{
    /* box-shadow: 0 10px 500px -100px rgba(0,64,128,.09); */
/* transition:0.5s; */
/* transition-timing-function:linear; */
border-color: rgba(224, 224, 224, 0.658);
border-style: groove;
/* transition-duration:1s; */
}
  
#Bdr:hover{
  /* box-shadow:1px 1px 10px rgba(34, 34, 34, 0.651);
  z-index:10;
  transform: translate(0px,25px);
  border-color: rgba(51, 121, 100, 0.699); */
}