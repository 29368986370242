.backgroundBlack
{
    clip-path: polygon(0 0, 100% 0%, 100% 78%, 0% 100%);
}
.image
{
    clip-path: polygon(0 0, 100% 0%, 100% 85%, 0% 100%);
}
#NavHover:hover{
    color: #8B014A;
}
.hello{
	box-shadow:0px 0px 5px 1px  #3b3b3b41 ,0px 0px 5px 1px  #5757573d;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
}
.hello:hover{
	box-shadow:0px 0px 3px 1px  #3b3b3b41 ,0px 0px 3px 1px  #5757573d;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.397);
}
#hig{
    box-shadow:0px 0px 8px 1px  #000000a2 inset ,0px 0px 8px 1px  #003f47a4 inset, 0px 0px 18px 1px  #0000004d ,0px 0px 5px 1px  #22222259 ;
    background-color: rgb(233, 233, 233);
}