.App {
  text-align: center;
}

/*stling for Volume controll*/
/* styles.css */
.video-controls {
  display: flex;
  align-items: center;
  flex-direction: ;
  justify-content: space-between;
  padding: 0 10px; /* Adjust the padding as needed */
  background-color: rgba(0, 0, 0, 0.8);
}

.volume-changer {
  flex: 1;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.volume-changer input[type="range"] {
  width: 100%; /* Full width */
  max-width: 200px; /* Adjust this value as needed */
}

.volume-changer span {
  color: #fff;
  font-size: 0.875rem; /* Adjust the font size as needed */
  margin-left: 10px;
}

.seek-bar {
  flex: 3;
}

.seek-bar input[type="range"] {
  width: 100%;
}
